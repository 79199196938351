import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { ChangePassword } from 'types/change-password';
import { CHANGE_PASSWORD_SCHEMA } from 'schema/change-password';

import Button from 'ui-kit/button/button';
import PasswordRules from 'components/password-rules/password-rules.component';
import PasswordText from 'ui-kit/password-text/password-text';

import { ChangePasswordProps } from './change-password-form.props';

const ChangePasswordForm = ({ onSubmit, style }: ChangePasswordProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'ChangePasswordForm';
    const handleSubmit = (values: Partial<ChangePassword>) => {
        onSubmit(values as ChangePassword);
    };
    return (
        <Container fluid style={style}>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p className="form-instructions">{t('changePassword.labels.formInstructions')}</p>
                    <Formik<Partial<ChangePassword>>
                        onSubmit={handleSubmit}
                        validationSchema={CHANGE_PASSWORD_SCHEMA}
                        initialValues={{
                            password: undefined,
                            passwordConfirm: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="change-password-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="password"
                                            label={t('registration.password')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            touched={formik.touched.password}
                                            value={formik.values?.password}
                                            errors={formik.errors?.password}
                                        />
                                        <PasswordRules
                                            errors={formik.errors?.password}
                                            touched={formik.touched.password}
                                            password={formik.values?.password}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="passwordConfirm"
                                            label={t('registration.passwordConfirm')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.password || formik.errors?.passwordConfirm
                                                    ? t(`registration.errors.passwordConfirm`)
                                                    : undefined
                                            }
                                            touched={formik.touched.passwordConfirm || formik.touched.password}
                                            value={formik.values?.passwordConfirm}
                                            showErrorsText={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Button
                                            label={t('changePassword.labels.submit')}
                                            variant="primary"
                                            type="submit"
                                            disabled={!formik.dirty || (formik.dirty && !formik.isValid)}
                                            dataGAFormName={formName}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ChangePasswordForm;
