import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { ChangePassword } from 'types/change-password';
import ChangePasswordForm from 'components/change-password-form/change-password-form.component';

const ForgotPassword = (): ReactElement => {
    const { t } = useTranslation();

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    return (
        <WorkflowLayout
            backgroundImage={imageData.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('changePassword.labels.title') }}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('changePassword.labels.eyebrowText')}
                title={t('changePassword.labels.title')}
            >
                <ChangePasswordForm onSubmit={(changePassword: ChangePassword) => console.log(changePassword)} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ForgotPassword;
